import styled, { css, keyframes } from 'styled-components';
import { px, Button } from '@liasincontrol/ui-basics';
import * as Domain from '@liasincontrol/domain';

// Animations for slide- and fade-in from the top or from the right
const aniSlideInRight = keyframes`
    0% { transform: translate(200%, ${px(-8)}); opacity: 0; }
    100% { transform: translate(100%, ${px(-8)}); opacity: 1; }
`;

const aniSlideInTop = keyframes`
    0% { transform: translate(0, -200%); opacity: 0; }
    100% { transform: translate(0, -100%); opacity: 1; }
`;

const slideAnimationRight = css`
    animation: ${aniSlideInRight} 0.2s ease forwards;
`;

const slideAnimationTop = css`
    animation: ${aniSlideInTop} 0.2s ease forwards;
`;

const horizontalButtons = css`
    grid-auto-flow: column;
    top: calc(0.5rem);
    right: calc(-0.5rem);
    padding: 0;
    padding-bottom: ${px(12)}; 
`;

const verticalButtons = (buttonCount: number) => css`
    grid-auto-flow: row;
    right: calc(-${px(36)} - 0.5rem);
    top: calc(${buttonCount} * ${px(36)} + ${px(16)}); 
    padding: 0;
    padding-top: ${px(7)}; 
    padding-right: ${px(12)}; 
`;

const ButtonContainerPopup = styled.div <{ direction?: number, verticalOffset?: number, hotizontalOffset?: number } > `
    display: grid;
    z-index: 999;
    grid-gap: ${px(8)};
    grid-auto-flow: ${(p) => (p.direction === Domain.Publisher.Direction.Horizontal ? 'column' : 'row')};
    margin-top: ${(p) => (p.verticalOffset ? `${p.verticalOffset}px` : '')}; 
    margin-left: ${(p) => (p.hotizontalOffset ? `${p.hotizontalOffset}px` : '')};     
`;

const ButtonContainerTopRight = styled.div <{ direction?: number, buttonCount: number, verticalOffset?: number, hotizontalOffset?: number } > `
    position: absolute;    
    display: grid;
    z-index: 999;    
    grid-gap: ${px(8)};    
    margin-top: ${(p) => (p.verticalOffset ? `${p.verticalOffset}px` : '')}; 
    margin-left: ${(p) => (p.hotizontalOffset ? `${p.hotizontalOffset}px` : '')};  
    
    ${(p) => (p.direction ? slideAnimationTop : slideAnimationRight)};
    ${(p) => (p.direction === Domain.Publisher.Direction.Vertical ? verticalButtons(p.buttonCount) : horizontalButtons)};
`;

const ActionButton = (styled(Button)`
        > span {
            padding: 0;
            height: ${px(40)};
            width: ${px(40)};
        }` ) as typeof Button;

export { ButtonContainerPopup, ButtonContainerTopRight, ActionButton }