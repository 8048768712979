import React, { useEffect, useState } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import Create from '@mui/icons-material/Create';
import * as Domain from '@liasincontrol/domain';
import { GridColumn, LsGrid } from '@liasincontrol/ui-devextreme';
import { TextElement } from '@liasincontrol/ui-elements';
import { Button, ModalDialog, ModalDialogFooter } from '@liasincontrol/ui-basics';
import { AnyFormData, FormHelper, FormMode, TextValidator, ValidationErrorData, ValidatorsDictionary, ValueType, } from '@liasincontrol/core-service';
import { ConfirmDialog } from '../../../../../../../Administrator/_shared/ConfirmDialog';

type VariableType = {
    name: string,
    value: string,
    rowIndex?: number,
    original?: string,
};

type Props = {
    label: string,
    values: VariableType[],
    displayExpr?: string | ((item) => string),
    allowCreate?: boolean,
    showTemplateValue?: boolean,
    allowDelete?: boolean,
    onAddRemoveVariable?: (newVariable: Record<string, string>) => void
}

const initialValue: { visible: boolean, data: VariableType } = { visible: false, data: null };

/**
 * Represents an UI component that renders a variable CRUD component.
 */
export const VariableListEditor: React.FC<Props> = (props) => {
    const [createForm, setCreateForm] = useState(initialValue);
    const [deleteConfirm, setDeleteConfirm] = useState(initialValue);
    const [editForm, setEditForm] = useState(initialValue);

    const onEdit = (data) => {
        setEditForm({ visible: true, data });
    };

    const onDelete = (data) => {
        setDeleteConfirm({ visible: true, data });
    }

    const availableColumns = getAvailableColumns(props.showTemplateValue, props.allowDelete, onEdit, onDelete);

    return <>
        <h3>{props.label}</h3>
        {!!props.allowCreate &&
            <Button
                id="btn-add-variable"
                btnbase="textbuttons"
                btntype="small_icon"
                onClick={() => setCreateForm(prev => ({ ...prev, visible: true }))}
            >
                Voeg toe
            </Button>}
        <VariableForm
            mode={FormMode.AddNew}
            label={props.label}
            values={props.values}
            isVisible={props.allowCreate && createForm.visible}
            variableName={createForm.data?.name}
            allowCreate={props.allowCreate}
            onCancel={() => setCreateForm(initialValue)}
            onSave={(values: Record<string, string>) => {
                props.onAddRemoveVariable({ ...values, type: "insert" });
                setCreateForm(initialValue);
            }}
        />
        <VariableForm
            mode={FormMode.Edit}
            label={props.label}
            values={props.values}
            isVisible={editForm.visible}
            variableName={editForm.data?.name}
            onCancel={() => setEditForm(initialValue)}
            onSave={(values: Record<string, string>) => {
                props.onAddRemoveVariable({ ...values, type: "update" });
                setEditForm(initialValue);
            }}
        />
        <VariableForm
            mode={FormMode.Delete}
            label={props.label}
            values={props.values}
            isVisible={!!props.allowDelete && deleteConfirm.visible}
            onCancel={() => setDeleteConfirm(initialValue)}
            onSave={() => {
                props.onAddRemoveVariable({ name: deleteConfirm.data.name, value: deleteConfirm.data.value, type: "remove" });
                setDeleteConfirm(initialValue);
            }}
        />
        <LsGrid
            dataSource={props.values}
            columns={availableColumns}
            showRowLines={false}
            showBorders={false}
            showColumnLines={false}
        />
    </>
};

type VariableFormProps = Props & {
    mode: FormMode,
    isVisible: boolean,
    variableName?: string,
    onSave: (newVariable: Record<string, string>) => void,
    onCancel: () => void,
}

const VariableForm: React.FC<VariableFormProps> = (props) => {
    const [form, setForm] = useState<AnyFormData>({
        values: { name: '', value: '' },
        touched: {},
        validationErrors: {},
        isValid: false,
    });

    // the component still exists in the virtual DOM, so the state has to be reset when the popup is hidden 
    useEffect(() => {
        if (props.isVisible) {
            return;
        }
        setForm({
            values: {},
            touched: {},
            validationErrors: {},
            isValid: false
        });
    }, [props.isVisible]);

    useEffect(() => {
        setForm(prev => ({ ...prev, values: { ...prev.values, name: props.variableName } }));
    }, [props.variableName]);

    if (!props.isVisible) {
        return null;
    }

    const validators = getValidators(props.values.flatMap(x => x.name));

    const storeFormValue = (value: string, systemId: keyof typeof validators) => {
        setForm((prevForm) => FormHelper.validateAndStoreFormValue<AnyFormData>(prevForm, value, validators, systemId));
    };

    const nameRestrictions = validators['name'] ? validators['name'].getRestrictions() : undefined;
    const nameValidationErrors = form.touched['name'] || !form.isValid ? form.validationErrors['name'] : [];
    const valueRestrictions = validators['value'] ? validators['value'].getRestrictions() : undefined;
    const valueValidationErrors = form.touched['value'] || !form.isValid ? form.validationErrors['value'] : [];
    const actionButtonLabel = props.mode === FormMode.AddNew ? 'Toevoegen' : props.mode === FormMode.Edit ? 'Bewerken' : 'Verwijderen';
    const modalTitle = props.mode === FormMode.AddNew ? 'Voeg toe variable' : props.mode ===  FormMode.Edit ? 'Bewerk variabele' : 'Verwijder variable';
    const actionButtonDisabled = props.mode ===  FormMode.Edit ? valueValidationErrors?.length > 0 : nameValidationErrors?.length > 0 || valueValidationErrors?.length > 0;

    if (props.mode === FormMode.Delete) {
        return (<ConfirmDialog
            look='interactive'
            title='Variable verwijderen'
            text='Weet u zeker dat u de variable wilt verwijderen? Dit kan impact hebben op de databronnen die gebruik maken van deze variabele.'
            confirmButtonText='Verwijderen'
            disableConfirmation={false}
            onCancel={props.onCancel}
            onConfirm={() => props.onSave(null)}
        />);
    }

    return (
        <ModalDialog
            id='variable-popup-form'
            settings={{
                look: 'message',
                title: modalTitle,
                footer: <ModalDialogFooter
                    leftButtonText='Annuleren'
                    onLeftButtonClick={props.onCancel}
                    rightButtonText={actionButtonLabel}
                    rightButtonDisabled={actionButtonDisabled}
                    onRightButtonClick={() => props.onSave(form.values)}
                />
            }}
        >
            <TextElement
                id='input-name'
                label='Naam'
                value={form.values.name}
                editorSettings={{
                    disabled: !props.allowCreate,
                    restrictions: nameRestrictions,
                    validationErrors: nameValidationErrors,
                    onChange: (value: string) => {
                        storeFormValue(value, 'name');
                    },
                }}
            />
            <TextElement
                id='input-value'
                label='Waarde'
                value={form.values.value}
                editorSettings={{
                    disabled: false,
                    restrictions: valueRestrictions,
                    validationErrors: valueValidationErrors,
                    clearable: true,
                    onChange: (value: string) => {
                        storeFormValue(value, 'value');
                    },
                }}
            />
        </ModalDialog>
    );
};

const getAvailableColumns = (
    showTemplateValue: boolean,
    allowDelete: boolean,
    onEdit: (data: ValueType) => void,
    onDelete: (data: ValueType) => void,
): GridColumn<VariableType>[] => {
    const availableColumns: GridColumn<VariableType>[] = [{
        name: 'name',
        title: 'Naam',
        width: '100px',
        editCellTemplate: (cellInfo) => {
            return <TextElement
                id={`input-name-${cellInfo.data.name}`}
                value={cellInfo.value}
                label={cellInfo.column.caption}
                editorSettings={{
                    disabled: true,
                    restrictions: {},
                    validationErrors: [],
                }}
            />;
        }
    },
    {
        name: 'value',
        title: 'Waarde',
        editCellTemplate: (cellInfo) => {
            return <TextElement
                id={`input-value-${cellInfo.data.name}`}
                value={cellInfo.value}
                label={cellInfo.column.caption}
                helpText={{ text: `Sjabloon waarde ${cellInfo.data.original}`, type: 'info' }}
                editorSettings={{
                    disabled: false,
                    clearable: true,
                    restrictions: { required: false, minLength: 1, maxLength: 50 },
                    validationErrors: [],
                }}
            />;
        }
    }];

    if (showTemplateValue) {
        availableColumns.push({
            name: 'original',
            title: 'Sjabloon waarde',
        });
    }

    availableColumns.push({
        title: '',
        type: 'buttons',
        align: 'center',
        width: '40px',
        renderCustom: ({ data }) => (
            <Button
                btnbase="iconbuttons"
                btntype="medium_transparentmain"
                icon={<Create />}
                onClick={() => onEdit(data)}
            />
        ),
    });

    if (allowDelete) {
        availableColumns.push({
            title: '',
            name: 'delete',
            type: 'buttons',
            align: 'center',
            width: '40px',
            renderCustom: ({ data }) => (<Button
                btnbase="iconbuttons"
                btntype="medium_transparentmain"
                icon={<DeleteIcon />}
                onClick={() => onDelete(data)}
            />),
        });
    }

    return availableColumns;
};

const getValidators = (variableNames: string[]): ValidatorsDictionary => {
    return {
        'name': new TextValidator(
            { required: true, stringMinLength: 2, stringMaxLength: 10, stringType: Domain.Shared.StringType.SingleLine },
            (value: string): ValidationErrorData[] => {
                const lowerCaseNamelist = variableNames.map(name => name?.toLocaleLowerCase() || '');
                if (lowerCaseNamelist.includes(value.toLocaleLowerCase())) {
                    return [{ error: 'Naam is niet uniek.' }];
                }

                const unallowedCharacter = regexAlphaNumeric.test(value);
                if (!unallowedCharacter) {
                    return [{ error: 'Niet toegestaan om teken te gebruiken.' }];
                }
                return [];
            }),
        'value': new TextValidator({ required: false, stringMaxLength: 50, stringType: Domain.Shared.StringType.SingleLine })
    };
};

const regexAlphaNumeric = /^([a-zA-Z0-9_]+$)/;
