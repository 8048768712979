import styled from 'styled-components';
import { px, palette } from '@liasincontrol/ui-basics';
import { lineHeightCoefficient } from '../../../Controls/SharedControlElements/index.styled';

const TabContainer = styled.div<{
    primaryPublicationColor?: string,
    textFontSize?: string,
    textColor?: string,
}>`
    display: block;
    position: relative;
    padding: 0 ${px(8)} ${px(8)} ${px(8)};
    
    font-size: ${(p) => (p.textFontSize ? `${p.textFontSize}px` : 'inherit')};
    line-height: ${(p) => (p.textFontSize ? `${+p.textFontSize * lineHeightCoefficient}px` : 'inherit')};

    .dx-widget {
        color: inherit!important;
        font-size: inherit!important;
        line-height: inherit!important;
    }

    .dx-tabs {
        .dx-tab {
            padding: 0;
            color: ${({ textColor }) => textColor};

            &.dx-tab-selected {
                color: ${({ textColor }) => textColor};
                background-color: ${palette.grey4};

                &::after {
                    background-color: ${({ primaryPublicationColor }) => primaryPublicationColor};
                }
            }

            .dx-icon-div {
                margin-left: 0.5rem;
            }

            &.dx-state-hover,
            &.dx-state-active {
                background-color: ${palette.grey4};

                .dx-inkripple-wave {
                    background-color: #00000015;
                }
            }

            .dx-tab-content {                
                    width: 100%;
                    font-weight: 500;
                    .tab-label .dx-icon {
                        display: inline-block;
                        margin-right: 0;
                        margin-left: 7px;
                        font-size: ${(p) => (p.textFontSize ? `${+p.textFontSize * 0.8}px` : 'inherit')};
                        line-height: ${(p) => (p.textFontSize ? `${+p.textFontSize * 0.8 * lineHeightCoefficient}px` : 'inherit')};
                        color: ${({ textColor }) => textColor};
                    }

                    .tab-label svg {
                        fill: ${({ textColor }) => textColor};
                        stroke: ${({ textColor }) => textColor};
                        width: ${(p) => (p.textFontSize ? `${Math.ceil(+p.textFontSize * 1.2)}px` : 'inherit')};
                        height: ${(p) => (p.textFontSize ? `${Math.ceil(+p.textFontSize * 1.2)}px` : 'inherit')};
                    }
                
            }
        }

        &.dx-state-focused .dx-tab.dx-state-focused {
            background-color: ${palette.grey4};
        }

    }

    .dx-item {
        overflow: auto;
        &.dx-tabpanel-tab.dx-tab-selected {
            overflow: visible;
        }
    }
`;

const PlaceholderWrapper = styled.div`
    padding-top: 17px;
    display: flex;
    justify-content: flex-end;
    padding-bottom: 10px;  
`;

const PlaceholderContent = styled.div`
    max-width: ${px(350)};
    margin: 10px auto;
    text-align: center;
`;

const TabHeader = styled.div`
    white-space: nowrap;
    padding: 6px 12px;
    width: 100%;
    display: flex;
    align-items: center;
    text-align: center;   
`;

const TabName = styled.div<{ orientation?: 'top' | 'end' | 'bottom' | 'start' }>`
    display: inline-flex;
    width: auto;
    align-items: center;
    flex-grow: 1;
    justify-content: start;
    flex-direction: ${({ orientation }) =>
        orientation === 'bottom' ? 'column-reverse' :
            orientation === 'end' ? 'row-reverse' :
                orientation === 'start' ? 'row' :
                    'column'
    };
`;

const styledComponents = {
    TabContainer, PlaceholderContent, PlaceholderWrapper, TabHeader, TabName
};
export default styledComponents;