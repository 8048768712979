import React, { useState } from 'react';
import { SortableHandle } from 'react-sortable-hoc';
import { Button, IDataItemProps, InputCheckbox, InputToggle, Label } from '@liasincontrol/ui-basics';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

import FormatAlignCenterIcon from '@mui/icons-material/FormatAlignCenter';
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';
import FormatAlignRightIcon from '@mui/icons-material/FormatAlignRight';

import { ILsColumnValue } from './columnbuilder';
import * as Styled from './columnbuilderitem.styled';
import { GroupRenderMode, renderModeAvailable, generateSafeId } from '../../helpers/renderModes';
import { SelectBoxField } from '../select-box';

type Props = {
    /**
     * Defines the value of a column item.
     */
    readonly value: ILsColumnValue,
    /**
     * Defines the index of a column item.
     */
    readonly index: number,
    /**
     * Defines the height of a column item.
     */
    readonly height?: number,
    /**
     * Determines if a column is disabled.
     */
    readonly disabled?: boolean,
    /**
     * Defines an event handler triggered when the column item value has changed.
     */
    readonly onValueChanged: (changedValue: ILsColumnValue, index: number) => void,

    /** 
     * Available actions array
     */
    readonly allowedActions: Record<string, boolean>,

    readonly children?: any,

    readonly expandedFields: string[];
    readonly onExpand?: (field: string) => void,

}

export const ColumnBuilderItem: React.FC<Props> = (props) => {
    const [focusedCaption, setFocusedCaption] = useState<boolean>(false);
    const [focusedDisplayFormat, setFocusedDisplayFormat] = useState<boolean>(false);
    const [focusedGroupIndex, setFocusedGroupIndex] = useState<boolean>(false);
    const [cursor, setCursor] = useState<number>(0);

    const onVisibilityClicked = (event: React.MouseEvent<any>): void => {
        if (!props.disabled) {
            const value = props.value;
            value.visible = !value.visible;
            props.onValueChanged(value, props.index);
        }
    }

    const onAlignmentClicked = (event: React.MouseEvent<any>): void => {
        if (!props.disabled) {
            const value = props.value;
            value.alignment = value.alignment === 'left' ? 'center' : (value.alignment === 'center' ? 'right' : 'left');
            props.onValueChanged(value, props.index);
        }
    }

    const onAdvancedSettingsClicked = (event: React.MouseEvent<any>): void => {
        if (!props.disabled) {
            props.onExpand(generateSafeId(props.value.dataField));
        }
    }

    const onCaptionChanged = (event: React.ChangeEvent<HTMLInputElement>): void => {
        if (!props.disabled) {
            setCursor(event.target.selectionStart || 0);
            setFocusedCaption(true);
            const columnValue = props.value;
            if (event.target.value) {
                columnValue.caption = event.target.value;
            } else {
                delete columnValue.caption;
            }
            props.onValueChanged(columnValue, props.index);
        }
    }

    const onDisplayFormatChanged = (event: React.ChangeEvent<HTMLInputElement>): void => {
        if (!props.disabled) {
            setCursor(event.target.selectionStart || 0);
            setFocusedDisplayFormat(true);
            const columnValue = props.value;
            columnValue.format = event.target.value;
            props.onValueChanged(columnValue, props.index);
        }
    }

    const onSumFooterChanged = (value: boolean): void => {
        if (!props.disabled) {
            const columnValue = props.value;
            columnValue.showTotalsSummary = value;
            props.onValueChanged(columnValue, props.index);
        }
    }

    const onHighlightedColumnChanged = (value: boolean): void => {
        if (!props.disabled) {
            const columnValue = props.value;
            if (value) {
                columnValue.cssClass = highlightedCellClass;
            } else {
                delete columnValue.cssClass;
            }
            props.onValueChanged(columnValue, props.index);
        }
    }

    const onShowCaptionChanged = (value: boolean): void => {
        if (props.disabled) return;

        const columnValue = props.value;
        columnValue.showCaption = value;
        props.onValueChanged(columnValue, props.index);

    }

    const onDivideChanged = (selected?: IDataItemProps<1 | 1000 | 1000000>): void => {
        if (!props.disabled) {
            const columnValue = props.value;
            if (selected) {
                columnValue.divide = selected.value;
            } else {
                delete columnValue.divide;
            }
            props.onValueChanged(columnValue, props.index);
        }
    }

    const onSortChanged = (selected?: IDataItemProps<'asc' | 'desc'> | undefined): void => {
        if (!props.disabled) {
            const columnValue = props.value;
            if (selected) {
                columnValue.sortOrder = selected.value;
            } else {
                delete columnValue.sortOrder;
                delete columnValue.sortIndex;
            }
            props.onValueChanged(columnValue, props.index);
        }
    }

    const onWidthChanged = (selected?: IDataItemProps<number>): void => {
        if (!props.disabled) {
            const columnValue = props.value;
            if (selected) {
                columnValue.columnWidth = selected.value;
            } else {
                delete columnValue.columnWidth;
            }
            props.onValueChanged(columnValue, props.index);
        }
    }

    const onRenderModeChanged = (selected?: IDataItemProps<GroupRenderMode>): void => {
        if (!props.disabled) {
            const columnValue = props.value;
            if (selected) {
                columnValue.groupRenderMode = selected.value;
            } else {
                delete columnValue.groupRenderMode;
            }
            props.onValueChanged(columnValue, props.index);
        }
    }

    const onGroupIndexChanged = (event: React.ChangeEvent<HTMLInputElement>): void => {
        if (!props.disabled) {
            setCursor(event.target.selectionStart || 0);
            setFocusedGroupIndex(true);
            const columnValue = props.value;
            if (event.target.value) {
                if (!isNaN(Number(event.target.value))) {
                    columnValue.groupIndex = +event.target.value;
                }
            } else {
                delete columnValue.groupIndex;
            }
            props.onValueChanged(columnValue, props.index);
        }
    }

    const onGroupExpandedChanged = (value: boolean): void => {
        if (!props.disabled) {
            const columnValue = props.value;
            columnValue.autoExpandGroup = value;
            props.onValueChanged(columnValue, props.index);
        }
    }

    const onGroupSummaryChanged = (selected?: IDataItemProps<'header-summary' | 'footer-summary'>): void => {
        if (props.disabled) {
            return;
        }

        const columnValue = props.value;
        if (selected) {
            if (selected.value === 'header-summary') {
                columnValue.showGroupHeaderSummary = true;
                delete columnValue.showGroupFooterSummary;
            } else if (selected.value === 'footer-summary') {
                columnValue.showGroupFooterSummary = true;
                delete columnValue.showGroupHeaderSummary;
            }
        } else {
            delete columnValue.showGroupHeaderSummary;
            delete columnValue.showGroupFooterSummary;
        }
        props.onValueChanged(columnValue, props.index);
    };

    const isExpanded = props.expandedFields.some(i => i === generateSafeId(props.value.dataField));

    const DragHandle = SortableHandle(() => <Styled.StyledIconDrag disabled={props.disabled} />);
    const columnBuilderTitle = renderModeAvailable.includes(props.value.propertyGroupKind) ? props.value.propertyGroupName || '' : props.value!.dataField;
    const txtLabel = <Styled.TxtLabel title={columnBuilderTitle}>{columnBuilderTitle}</Styled.TxtLabel>;
    const visibiltyIcon = props.value!.visible ? <VisibilityIcon /> : <VisibilityOffIcon />;
    const visibleAction = <Button
        disabled={props.disabled}
        btnbase='iconbuttons'
        btntype='medium_background'
        aria-label='Zichtbaarheid'
        icon={visibiltyIcon}
        onClick={onVisibilityClicked} />;
    const alignmentIcon = props.value!.alignment === 'right' ? <FormatAlignRightIcon /> : (props.value!.alignment === 'left' ? <FormatAlignLeftIcon /> : <FormatAlignCenterIcon />);
    const alignmentAction = <Button
        disabled={props.disabled}
        btnbase='iconbuttons'
        btntype='medium_background'
        aria-label='Uitlijning'
        icon={alignmentIcon}
        onClick={onAlignmentClicked} />;
    const advancedSettingsIcon = isExpanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />;
    const advancedSettingsAction = <Button
        disabled={props.disabled}
        btnbase='iconbuttons'
        btntype='medium_background'
        aria-label='Geavanceerde instellingen'
        icon={advancedSettingsIcon}
        onClick={onAdvancedSettingsClicked} />;

    const defaultFocus = (event: React.FocusEvent<HTMLInputElement>) => {
        event.target.selectionStart = cursor;
        event.target.selectionEnd = cursor;
    };

    const ExtraLineElement = (): JSX.Element | null => {
        if (!isExpanded) {
            return null;
        }

        const getGroupSummaryType = (columnValue: ILsColumnValue): IDataItemProps<'header-summary' | 'footer-summary'> | undefined => {
            const defaultOption = { value: undefined, label: undefined };

            if (!columnValue) {
                return defaultOption;
            } else if (columnValue.showGroupHeaderSummary) {
                return groupOptions.find(go => go.value === 'header-summary');
            } else if (columnValue.showGroupFooterSummary) {
                return groupOptions.find(go => go.value === 'footer-summary');
            }

            return defaultOption;
        };

        return (
            <Styled.ExtraLineWrap>
                {props.allowedActions?.canCaption &&
                    <Styled.PanelSection>
                        <Label id={`${generateSafeId(props.value.dataField)}-caption-label`} htmlFor={`${generateSafeId(props.value.dataField)}-caption`} text='Kolomtitel' />
                        <Styled.InputContainer>
                            <Styled.StyledInput
                                type='text'
                                id={`${generateSafeId(props.value.dataField)}-caption`}
                                disabled={props.disabled}
                                value={props.value.caption as string}
                                autoFocus={focusedCaption}
                                onChange={onCaptionChanged}
                                onBlur={(event: React.FocusEvent<HTMLInputElement>) => setFocusedCaption(false)}
                                onFocus={defaultFocus}
                                placeholder='Type hier…'
                            />
                        </Styled.InputContainer>
                    </Styled.PanelSection>
                }
                {props.allowedActions?.canHideCaption &&
                    <Styled.PanelSection>
                        <Label id={`${generateSafeId(props.value.dataField)}-showCaption-label`} htmlFor={`${generateSafeId(props.value.dataField)}-showCaption`} text='Toon veldnaam' />
                        <InputToggle
                            id={`showCaption-${generateSafeId(props.value.dataField)}`}
                            textOff='Uit'
                            textOn='Aan'
                            selected={!!props.value.showCaption}
                            disabled={props.disabled}
                            onChange={onShowCaptionChanged}
                        />
                    </Styled.PanelSection>
                }
                {props.allowedActions?.canDivide && props.value.dataType === 'number' &&
                    <Styled.PanelSection>
                        <SelectBoxField<IDataItemProps<1 | 1000 | 1000000>>
                            id={`divide-${generateSafeId(props.value.dataField)}`}
                            label='Delen door'
                            displayExpr='label'
                            disabled={props.disabled}
                            optionItems={divideOptions}
                            clearable={false}
                            searchable={false}
                            value={props.value.divide ? divideOptions.find(o => o.value === props.value.divide) : divideOptions[0]}
                            onChange={onDivideChanged}
                        />
                    </Styled.PanelSection>
                }
                {props.allowedActions?.canFormat && !['string', 'date', 'datetime'].includes(props.value.dataType) &&
                    <Styled.PanelSection>
                        <Label id={`${generateSafeId(props.value.dataField)}-format-label`} htmlFor={`${generateSafeId(props.value.dataField)}-format`} text='Opmaakmasker' />
                        <Styled.InputContainer>
                            <Styled.StyledInput
                                type='text'
                                id={`${generateSafeId(props.value.dataField)}-format`}
                                disabled={props.disabled}
                                value={props.value.format as string}
                                autoFocus={focusedDisplayFormat}
                                onChange={onDisplayFormatChanged}
                                onBlur={(event: React.FocusEvent<HTMLInputElement>) => setFocusedDisplayFormat(false)}
                                onFocus={defaultFocus}
                                placeholder='Type hier…'
                            />
                        </Styled.InputContainer>
                    </Styled.PanelSection>
                }
                {props.allowedActions?.canSummarize &&
                    props.value.dataType === 'number' &&
                    <Styled.PanelSection>
                        <InputCheckbox
                            id={`${generateSafeId(props.value.dataField)}-footer`}
                            label='Sommeren'
                            ariaLabel='Sommeren'
                            selected={props.value.showTotalsSummary}
                            disabled={props.disabled}
                            onChange={onSumFooterChanged}
                        />
                    </Styled.PanelSection>
                }
                {props.allowedActions?.canSort &&
                    <Styled.PanelSection>
                        <SelectBoxField<IDataItemProps<'asc' | 'desc'>>
                            id={`sort-${generateSafeId(props.value.dataField)}`}
                            label='Sorteren'
                            displayExpr='label'
                            optionItems={sortOptions}
                            mandatory={true}
                            value={props.value.sortOrder ? sortOptions.find(o => o.value === props.value.sortOrder) : sortOptions[0]}
                            onChange={onSortChanged}
                            clearable={false}
                            searchable={false}
                        />
                    </Styled.PanelSection>
                }

                {props.allowedActions?.canSetColumnWidth &&
                    <Styled.PanelSection>
                        <SelectBoxField<IDataItemProps<number>>
                            id={`setWidth-${generateSafeId(props.value.dataField)}`}
                            label='Kolombreedte'
                            displayExpr='label'
                            disabled={props.disabled}
                            optionItems={widthOptions}
                            clearable={false}
                            searchable={false}
                            value={props.value.columnWidth ? widthOptions.find(o => o.value === props.value.columnWidth) : widthOptions[0]}
                            onChange={onWidthChanged}
                        />
                    </Styled.PanelSection>
                }

                {props.value.propertyGroupKind && renderModeAvailable.includes(props.value.propertyGroupKind) &&
                    <Styled.PanelSection>
                        <SelectBoxField<IDataItemProps<GroupRenderMode>>
                            id={`rendermode-${generateSafeId(props.value.dataField)}`}
                            label='Toon als'
                            displayExpr='label'
                            disabled={props.disabled}
                            optionItems={props.value.propertyGroupName === "ElementDefinition" ? elementDefinitionModeOptions : renderModeOptions}
                            clearable={false}
                            searchable={false}
                            value={props.value.groupRenderMode ? renderModeOptions.find(o => o.value === props.value.groupRenderMode) : renderModeOptions[0]}
                            onChange={onRenderModeChanged}
                        />
                    </Styled.PanelSection>
                }

                {props.allowedActions?.canHighLight &&
                    <Styled.PanelSection>
                        <Label id={`${generateSafeId(props.value.dataField)}-highlight-label`} htmlFor={`${generateSafeId(props.value.dataField)}-highlight`} text='Kolom markeren' />
                        <InputToggle
                            id={`${generateSafeId(props.value.dataField)}-highlighted`}
                            textOff='Uit'
                            textOn='Aan'
                            selected={props.value.cssClass === highlightedCellClass}
                            disabled={props.disabled}
                            onChange={onHighlightedColumnChanged}
                        />
                    </Styled.PanelSection>
                }
                {props.allowedActions?.canGroup &&
                    <Styled.PanelSection>
                        <Label id={`${generateSafeId(props.value.dataField)}-group-label`} htmlFor={`${generateSafeId(props.value.dataField)}-group`} text='Groeperen' />
                        <Styled.InputContainer2>
                            <Styled.StyledInput
                                type='text'
                                pattern='[0-9]*'
                                id={`${generateSafeId(props.value.dataField)}-group`}
                                className='one-third'
                                disabled={props.disabled}
                                value={props.value.groupIndex}
                                autoFocus={focusedGroupIndex}
                                onChange={onGroupIndexChanged}
                                onBlur={(event: React.FocusEvent<HTMLInputElement>) => setFocusedGroupIndex(false)}
                                onFocus={defaultFocus}
                                placeholder='Index'
                            />
                            {
                                props.value.dataType === 'number' &&
                                <SelectBoxField<IDataItemProps<'header-summary' | 'footer-summary'>>
                                    id={`group-summary-${generateSafeId(props.value.dataField)}`}
                                    disabled={props.disabled}
                                    label={undefined}
                                    displayExpr='label'
                                    optionItems={groupOptions}
                                    clearable={true}
                                    searchable={false}
                                    value={getGroupSummaryType(props.value)}
                                    onChange={onGroupSummaryChanged}
                                />
                            }
                        </Styled.InputContainer2>
                    </Styled.PanelSection>
                }
                {props.allowedActions?.canExpandGroup &&
                    <Styled.PanelSection>
                        <Label id={`${generateSafeId(props.value.dataField)}-autoExpand-label`} htmlFor={`${generateSafeId(props.value.dataField)}-autoExpand`} text='Groep standaard uitvouwen' />
                        <InputToggle
                            id={`${generateSafeId(props.value.dataField)}-autoExpand`}
                            textOff='Uit'
                            textOn='Aan'
                            selected={props.value.autoExpandGroup}
                            disabled={props.disabled || props.value.groupIndex === undefined}
                            onChange={onGroupExpandedChanged}
                        />
                    </Styled.PanelSection>
                }
            </Styled.ExtraLineWrap>
        );
    };

    return (
        <Styled.Li disabled={props.disabled} expanded={isExpanded} height={props.height} role='button'>
            <Styled.MainLineWrap>
                {props.allowedActions?.canDrag && <Styled.DragWrap aria-label={`${props.value!.dataField} verslepen`} title={`${props.value!.dataField} verslepen`}>
                    <DragHandle />
                </Styled.DragWrap>
                }
                {txtLabel}
                {props.children}
                <Styled.ActionsWrap>
                    {props.allowedActions?.canHide && visibleAction}
                    {props.allowedActions?.canAlign && alignmentAction}
                    {advancedSettingsAction}
                </Styled.ActionsWrap>
            </Styled.MainLineWrap>
            <ExtraLineElement />
        </Styled.Li>
    );
};
//canSetColumnWidth
const highlightedCellClass = 'cell-highlighted';
const divideOptions: IDataItemProps<1 | 1000 | 1000000>[] = [
    { label: '1', value: 1 },
    { label: '1.000', value: 1000 },
    { label: '1.000.000', value: 1000000 },
];
const sortOptions: IDataItemProps<'asc' | 'desc' | undefined>[] = [
    { label: 'Niet sorteren', value: undefined },
    { label: 'Oplopend', value: 'asc' },
    { label: 'Aflopend', value: 'desc' }
];

const elementDefinitionModeOptions: IDataItemProps<GroupRenderMode | undefined>[] = [
    { label: 'Icoon en naam', value: GroupRenderMode.ColoredIconName },
    { label: 'Icoon', value: GroupRenderMode.ColoredIcon },
    { label: 'Naam', value: GroupRenderMode.Name },
];

const renderModeOptions: IDataItemProps<GroupRenderMode | undefined>[] = [
    ...elementDefinitionModeOptions,
    { label: 'Waarde', value: GroupRenderMode.Value },
];

const groupOptions: IDataItemProps<'header-summary' | 'footer-summary' | undefined>[] = [
    { label: 'Som in kopregel', value: 'header-summary' },
    { label: 'Som in voetregel', value: 'footer-summary' }
];

const widthOptions: IDataItemProps<number | undefined>[] = [
    { label: 'auto', value: undefined },
    { label: '1', value: 1 },
    { label: '2', value: 2 },
    { label: '3', value: 3 },
    { label: '4', value: 4 },
    { label: '5', value: 5 },
    { label: '6', value: 6 },
    { label: '7', value: 7 },
    { label: '8', value: 8 },
    { label: '9', value: 9 },
    { label: '10', value: 10 },
    { label: '12', value: 12 },
    { label: '14', value: 14 },
    { label: '16', value: 16 },
    { label: '18', value: 18 },
    { label: '20', value: 20 }
];
